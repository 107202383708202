import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import AnnualReport from "./pages/innerpage/AnnualReport";
import BoardOfDirector from "./pages/innerpage/BoardOfDirector";
import MessageCm from "./pages/innerpage/MessageCm";
import ProgressReport from "./pages/innerpage/ProgressReport";
import Projects from "./pages/innerpage/Projects";
import QuaterlyReport from "./pages/innerpage/QuaterlyReport";
import Contact from "./pages/innerpage/Contact";
import Login from "./pages/user/Login";
import PrivateRoute from "./components/routes/PrivateRoute";
import Allbanners from "./components/admin/dashboard/dash-page/banner/AllBanners";
import AddBanner from "./components/admin/dashboard/dash-page/banner/AddBanner";
import AllProjects from "./components/admin/dashboard/dash-page/project/AllProjects";
import AddProject from "./components/admin/dashboard/dash-page/project/AddProject";
import InnerGallery from "./pages/innerpage/InnerGallery";
import PorjectDetail from "./pages/detail/PorjectDetail";
import VideoGallery from "./pages/innerpage/VideoGallery";
import News from "./pages/innerpage/News";
import OurTeam from "./pages/innerpage/OurTeam";
import Consultant from "./pages/innerpage/Consultant";
import KeyDates from "./pages/innerpage/KeyDates";
// import PopUp from "./components/header/PopUp";

const App = lazy(() => import("./App"));
const Introduction = lazy(() => import("./pages/innerpage/Introduction"));
const AboutBusiness = lazy(() => import("./pages/innerpage/AboutBusiness"));
const Dashboard = lazy(() => import("./components/admin/Dashboard"));

function Routess() {
  return (
    <>
      <Router>
        <Header />
        {/* <PopUp /> */}
        <Suspense fallback={<></>}>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/introduction" element={<Introduction />} />
            <Route path="/gallery" element={<InnerGallery />} />
            <Route path="/about-business" element={<AboutBusiness />} />
            <Route path="/message-from-chairman" element={<MessageCm />} />
            <Route path="/board-of-directors" element={<BoardOfDirector />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/consultant" element={<Consultant />} />
            <Route path="/key-dates" element={<KeyDates />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/project-details/Upper-Sankhuwa-Khola-Hydroelectric-Project"
              element={<PorjectDetail />}
            />
            <Route path="/progress-report" element={<ProgressReport />} />
            <Route path="/annual-report" element={<AnnualReport />} />
            <Route path="/quaterly-report" element={<QuaterlyReport />} />
            <Route path="/video-gallery" element={<VideoGallery />} />
            <Route path="/updates" element={<News />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/all-banners" element={<Allbanners />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/add-banner" element={<AddBanner />} />
            </Route>

            <Route element={<PrivateRoute />}>
              <Route path="/all-projects" element={<AllProjects />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/add-project" element={<AddProject />} />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
}

export default Routess;
